
.about-continer{
    text-align: center;
}
p{
    text-align: justify;
}
.social-media-links{
    text-align: center;
}
.social-media-links ul {
    display: flex;
    
    
}
.social-media-links ul li{
    margin-left: 20px;
    list-style-type: none;
    text-align: center;
}



 .about-continer  .quote{
  font-family: "SemiBold", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: Italic;
  font-size: 16px;
}